import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import loadable from "@loadable/component";
import store from "./store"; 

const Servicios = loadable(() => import("./containers/Servicios"));

ReactDOM.render(
    <Provider store={store}>
        <Servicios/>
    </Provider>, document.getElementById("servicios"));