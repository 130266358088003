import {SET_SERVICES,GET_SERVICE} from '../constants/serviceTypes';
const initialState = {
    services: {}
};
export default (state=initialState,action)=>{
    switch(action.type){
        case SET_SERVICES:
        return { ...state, services: action.payload };
        case GET_SERVICE:
        return { ...state, service:state.services[action.payload] }; 
        default:
            return state;
    }
}